<template>
  <page-container3>
    <a-layout>
      <a-layout>
        <a-layout-sider :width="430">
          <div class="ant-pro-table">
            <div class="ant-pro-table-search">
              <search-render
                ref="config_render"
                :form="search_config"
                :model="search_config.model"
                :options="search_config_options"
                :validateInfos="searchValidateInfos"
                @search="search"
              />
            </div>
            <a-card :body-style="{ padding: 0 }" ref="elRef">
              <div class="ant-pro-table-list-toolbar">
                <div class="ant-pro-table-list-toolbar-container">
                  <!--                  <div class="ant-pro-table-list-toolbar-left">-->
                  <!--                    <div class="ant-pro-table-list-toolbar-title">部门管理</div>-->
                  <!--                  </div>-->
                  <div class="ant-pro-table-list-toolbar-right">
                    <a-space align="center">
                      <a-button
                        type="primary"
                        @click="
                          () => {
                            editModal.visible = true;
                            editModal.title = '新建部门';
                            editModal.form.model = {};
                            editModal.aim_org_id = search_modelRef.aim_org_id;
                          }
                        "
                      >
                        <plus-outlined />
                        新建部门
                      </a-button>
                      <div class="ant-pro-table-list-toolbar-setting-item">
                        <a-tooltip title="表格斑马纹">
                          <a-switch
                            checked-children="开"
                            un-checked-children="关"
                            v-model:checked="state.stripe"
                          />
                        </a-tooltip>
                      </div>
                    </a-space>
                    <div class="ant-pro-table-list-toolbar-divider">
                      <a-divider type="vertical" />
                    </div>
                    <div class="ant-pro-table-list-toolbar-setting-item">
                      <a-tooltip title="刷新">
                        <reload-outlined @click="handleTableChange" />
                      </a-tooltip>
                    </div>
                    <div class="ant-pro-table-list-toolbar-setting-item">
                      <a-tooltip title="密度">
                        <a-dropdown :trigger="['click']" placement="bottomRight">
                          <column-height-outlined />
                          <template #overlay>
                            <a-menu
                              style="width: 80px"
                              :selected-keys="[state.tableSize]"
                              @click="
                                ({ key }) => {
                                  state.tableSize = key;
                                }
                              "
                            >
                              <a-menu-item key="default">
                                <a href="javascript:;">默认</a>
                              </a-menu-item>
                              <a-menu-item key="middle">
                                <a href="javascript:;">中等</a>
                              </a-menu-item>
                              <a-menu-item key="small">
                                <a href="javascript:;">紧凑</a>
                              </a-menu-item>
                            </a-menu>
                          </template>
                        </a-dropdown>
                      </a-tooltip>
                    </div>
                    <div class="ant-pro-table-list-toolbar-setting-item">
                      <a-popover
                        placement="bottomRight"
                        arrowPointAtCenter
                        trigger="click"
                        overlayClassName="ant-pro-table-column-setting-overlay"
                      >
                        <template #title>
                          <div class="ant-pro-table-column-setting-title">
                            <a-checkbox
                              v-model:checked="userColumnState.checkAll"
                              :indeterminate="userColumnState.indeterminate"
                              @change="handleColumnAllClick"
                            >
                              列展示
                            </a-checkbox>
                            <a @click="reset">重置</a>
                          </div>
                        </template>
                        <template #content>
                          <span class="ant-pro-table-column-setting-list">
                            <drag-container
                              lockAxis="y"
                              dragClass="ant-pro-table-drag-ghost"
                              dropClass="ant-pro-table-drop-ghost"
                              @drop="
                                ({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)
                              "
                            >
                              <draggable :key="column.key" v-for="column in dynamicColumnItems">
                                <div class="ant-pro-table-column-setting-list-item">
                                  <drag-icon />
                                  <a-checkbox
                                    :checked="column.checked"
                                    @change="handleColumnChange($event, column)"
                                  >
                                    {{ column.label }}
                                  </a-checkbox>
                                </div>
                              </draggable>
                            </drag-container>
                          </span>
                        </template>
                        <a-tooltip title="列设置">
                          <setting-outlined />
                        </a-tooltip>
                      </a-popover>
                    </div>
                    <div class="ant-pro-table-list-toolbar-setting-item">
                      <a-tooltip :title="screenState ? '退出全屏' : '全屏'">
                        <fullscreen-outlined v-if="!screenState" @click="setFull" />
                        <fullscreen-exit-outlined v-else @click="exitFull" />
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <a-affix :offset-top="top">
                <a-table
                  :scroll="{ y: tableSize.y }"
                  :customRow="
                    record => {
                      return {
                        onClick: event => {
                          state.currentRow = record;
                          setUserPageInfo({
                            current: 1,
                            ...user_search_modelRef,
                            group_id: record.id,
                          });
                          userReload();
                        },
                      };
                    }
                  "
                  :row-class-name="stripe"
                  :size="state.tableSize"
                  :loading="state.loading"
                  :columns="dynamicColumns"
                  :data-source="state.dataSource"
                  :rowClassName="setRowClassName"
                  :pagination="{
                    current: state.current,
                    pageSize: state.pageSize,
                    total: state.total,
                  }"
                  @change="handleTableChange"
                >
                  <template #index="{ index }">
                    <span>{{ index + 1 + state.pageSize * (state.current - 1) }}</span>
                  </template>
                  <template #status="{ text }">
                    <a-tag :color="statusMap[text].status">
                      {{ statusMap[text].text }}
                    </a-tag>
                  </template>
                  <template #action="{ record }">
                    <a-button
                      type="primary"
                      @click="() => handleOpenEdit(record)"
                      v-if="record.is_active"
                    >
                      修改
                    </a-button>
                    <a-popconfirm v-if="record.is_active" @confirm="() => handleDelete(record)">
                      <template #title>
                        <p>
                          是否确认删除
                          <span style="color: dodgerblue">{{ record.name }}</span>
                          ?
                        </p>
                      </template>
                      <a-button type="danger">删除</a-button>
                    </a-popconfirm>
                    <a-button
                      type="danger"
                      @click="() => handleRecover(record)"
                      v-if="!record.is_active"
                    >
                      恢复
                    </a-button>
                  </template>
                </a-table>
              </a-affix>
            </a-card>
          </div>
        </a-layout-sider>
        <!--user-->
        <a-layout-content>
          <div class="ant-pro-table-search">
            <search-render
              ref="user_config_render"
              :form="user_search_config"
              :model="user_search_config.model"
              :options="user_search_config_options"
              :validateInfos="userSearchValidateInfos"
              @search="userSearch"
            />
          </div>
          <a-card :body-style="{ padding: 0 }" ref="rightElRef">
            <div class="ant-pro-table-list-toolbar">
              <div class="ant-pro-table-list-toolbar-container">
                <!--                <div class="ant-pro-table-list-toolbar-left">-->
                <!--                  <div class="ant-pro-table-list-toolbar-title">部门管理</div>-->
                <!--                </div>-->
                <div class="ant-pro-table-list-toolbar-right">
                  <a-space align="center">
                    <div class="ant-pro-table-list-toolbar-setting-item">
                      <a-tooltip title="表格斑马纹">
                        <a-switch
                          checked-children="开"
                          un-checked-children="关"
                          v-model:checked="userState.stripe"
                        />
                      </a-tooltip>
                    </div>
                  </a-space>
                  <div class="ant-pro-table-list-toolbar-divider">
                    <a-divider type="vertical" />
                  </div>
                  <div class="ant-pro-table-list-toolbar-setting-item">
                    <a-tooltip title="刷新">
                      <reload-outlined @click="handleUserTableChange" />
                    </a-tooltip>
                  </div>
                  <div class="ant-pro-table-list-toolbar-setting-item">
                    <a-tooltip title="密度">
                      <a-dropdown :trigger="['click']" placement="bottomRight">
                        <column-height-outlined />
                        <template #overlay>
                          <a-menu
                            style="width: 80px"
                            :selected-keys="[userState.tableSize]"
                            @click="
                              ({ key }) => {
                                userState.tableSize = key;
                              }
                            "
                          >
                            <a-menu-item key="default">
                              <a href="javascript:;">默认</a>
                            </a-menu-item>
                            <a-menu-item key="middle">
                              <a href="javascript:;">中等</a>
                            </a-menu-item>
                            <a-menu-item key="small">
                              <a href="javascript:;">紧凑</a>
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </a-tooltip>
                  </div>
                  <div class="ant-pro-table-list-toolbar-setting-item">
                    <a-popover
                      placement="bottomRight"
                      arrowPointAtCenter
                      trigger="click"
                      overlayClassName="ant-pro-table-column-setting-overlay"
                    >
                      <template #title>
                        <div class="ant-pro-table-column-setting-title">
                          <a-checkbox
                            v-model:checked="userColumnState.checkAll"
                            :indeterminate="userColumnState.indeterminate"
                            @change="userHandleColumnChange"
                          >
                            列展示
                          </a-checkbox>
                          <a @click="userReset">重置</a>
                        </div>
                      </template>
                      <template #content>
                        <span class="ant-pro-table-column-setting-list">
                          <drag-container
                            lockAxis="y"
                            dragClass="ant-pro-table-drag-ghost"
                            dropClass="ant-pro-table-drop-ghost"
                            @drop="
                              ({ removedIndex, addedIndex }) => userMove(removedIndex, addedIndex)
                            "
                          >
                            <draggable :key="column.key" v-for="column in userDynamicColumnItems">
                              <div class="ant-pro-table-column-setting-list-item">
                                <drag-icon />
                                <a-checkbox
                                  :checked="column.checked"
                                  @change="userHandleColumnChange($event, column)"
                                >
                                  {{ column.label }}
                                </a-checkbox>
                              </div>
                            </draggable>
                          </drag-container>
                        </span>
                      </template>
                      <a-tooltip title="列设置">
                        <setting-outlined />
                      </a-tooltip>
                    </a-popover>
                  </div>
                  <div class="ant-pro-table-list-toolbar-setting-item">
                    <a-tooltip :title="userScreenState ? '退出全屏' : '全屏'">
                      <fullscreen-outlined v-if="!userScreenState" @click="setUserFull" />
                      <fullscreen-exit-outlined v-else @click="exitUserFull" />
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <a-affix :offset-top="top">
              <a-table
                :scroll="{ y: tableSize.y }"
                :row-class-name="userStripe"
                :size="userState.tableSize"
                :loading="userState.loading"
                :columns="userDynamicColumns"
                :data-source="userState.dataSource"
                :rowClassName="setRowClassName"
                :pagination="{
                  current: userState.current,
                  pageSize: userState.pageSize,
                  total: userState.total,
                }"
                @change="handleUserTableChange"
              >
                <template #index="{ index }">
                  <span>{{ index + 1 + userState.pageSize * (userState.current - 1) }}</span>
                </template>
                <template #status="{ text }">
                  <a-tag :color="statusMap[text].status">
                    {{ statusMap[text].text }}
                  </a-tag>
                </template>
                <template #group_list="{ text }">
                  <a-tag color="green" v-for="item in text" :key="item.id">
                    {{ item.name }}
                  </a-tag>
                </template>
              </a-table>
            </a-affix>
          </a-card>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <form-modal
      v-bind="editModal"
      v-if="editModal.visible"
      @cancel="
        () => {
          editModal.visible = false;
        }
      "
      @ok="handlerOk"
    />
  </page-container3>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, watch } from 'vue';
import { Badge, message } from 'ant-design-vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import { addGroup, queryGroup, queryMemberByGroup, recoverGroup, removeGroup, updateGroup } from '@/api/org/group';

import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '../../../components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'groupManage',
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const current_org = store.getters['user/current_org'];
    const tableSize = reactive({ y: window.innerHeight - 130 });
    const statusMap = {
      true: { text: '正常', status: 'green' },
      false: { text: t('禁用'), status: 'red' },
    };
    const status_list = [
      { text: t('全部'), value: '' },
      { text: '正常', value: true },
      { text: t('禁用'), value: false },
    ];
    const status = ['正常', '禁用'];

    const baseColumns: TableColumn[] = [
      {
        title: '序号',
        dataIndex: 'index',
        slots: { customRender: 'index' },
      },
      {
        title: '名称',
        dataIndex: 'name',
      },
      {
        title: '状态',
        dataIndex: 'is_active',
        slots: { customRender: 'status' },
        filters: [
          {
            text: status[0],
            value: true,
          },
          {
            text: status[1],
            value: false,
          },
        ],
      },
      {
        title: t('操作'),
        dataIndex: 'action',
        slots: { customRender: 'action' },
      },
    ];
    const baseUserColumns: TableColumn[] = [
      {
        title: '序号',
        dataIndex: 'index',
        slots: { customRender: 'index' },
      },
      {
        title: '人员姓名',
        dataIndex: 'realname',
      },
      {
        title: '手机号',
        dataIndex: 'user__username',
      },
      {
        title: '职务',
        dataIndex: 'title',
      },
      {
        title: '隶属部门',
        dataIndex: 'group_list',
        slots: { customRender: 'group_list' },
      },
    ];
    onMounted(() => {
      window.onresize = function () {
        tableSize.y = window.innerHeight - 130;
      };
    });
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, true);
    const {
      state: userColumnState,
      dynamicColumns: userDynamicColumns,
      dynamicColumnItems: userDynamicColumnItems,
      handleColumnAllClick: userHandleColumnAllClick,
      handleColumnChange: userHandleColumnChange,
      reset: userReset,
      move: userMove,
    } = useTableDynamicColumns(baseUserColumns, true);
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();

    // const [ elRef:userElRef, screenState:userScreenState, { setFull:setUserFull, exitFull:exitUserFull }] = useFullscreen();
    const search_modelRef = reactive({
      org_id: current_org.id,
      aim_org_id: current_org.id,
      is_active: true,
    });
    const user_search_modelRef = reactive({
      org_id: current_org.id,
      aim_org_id: current_org.id,
      is_active: true,
    });
    const search_config_options = {
      is_active: [
        { name: '全部', value: '' },
        { name: '正常', value: true },
        { name: '禁用', value: false },
      ],
    };
    const search_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'remoteselect',
          name: 'aim_org_id',
          label: '目标组织',
          label_i18n: '目标组织',
          placeholder_i18n: '请选择目标组织',
          disabled: false,
          labelKey: 'name',
          valueKey: 'id',
          placeholder: '请填组织id',
          datasourceType: 'remote',
          datasource: 'aim_org_id',
          mode: 'default',
          modalType: 'select-org-id',
        },
      ],
      rules: {
        name: [],
      },
      model: search_modelRef,
    };
    const { validateInfos: searchValidateInfos } = useForm(search_modelRef, {});

    const user_search_config_options = {};
    const user_search_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '姓名',
          label_i18n: '姓名',
          placeholder_i18n: '请填写姓名',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写姓名名称',
        },
      ],
      rules: {
        name: [],
      },
      model: user_search_modelRef,
    };
    const { validateInfos: userSearchValidateInfos } = useForm(user_search_modelRef, {});

    const { stripe, reload, setPageInfo, context: state } = useFetchData(queryGroup, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...search_modelRef,
      },
    });
    const {
      stripe: userStripe,
      reload: userReload,
      setPageInfo: setUserPageInfo,
      context: userState,
    } = useFetchData(queryMemberByGroup, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...user_search_modelRef,
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...filters,
        ...search_modelRef,
      });

      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...search_modelRef,
      });
      reload();
    };
    const handleUserTableChange = (
      { current: userCurrent, pageSize: userPageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setUserPageInfo({
        userCurrent: userCurrent ? userCurrent : state.current,
        userPageSize: userPageSize ? userPageSize : state.pageSize,
        ...filters,
        ...user_search_modelRef,
      });

      userReload();
    };

    const userSearch = () => {
      setUserPageInfo({
        current: 1,
        ...user_search_modelRef,
      });
      userReload();
    };
    // edit
    const edit_model_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'vertical',
      },
      fields: [
        {
          type: 'input',
          name: 'name',
          label: '部门名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写角色名称',
        },
        {
          type: 'remoteselect',
          name: 'aim_org_id',
          label: '目标组织',
          label_i18n: '目标组织',
          placeholder_i18n: '请选择目标组织',
          disabled: false,
          labelKey: 'name',
          valueKey: 'id',
          placeholder: '请填组织id',
          datasourceType: 'remote',
          datasource: 'aim_org_id',
          mode: 'default',
          modalType: 'select-org-id',
        },
      ],
      rules: {
        name: [{ required: true, message: '部门名称必须填写' }],
      },
      model: {
        id: null,
        name: '',
        desc: '',
      },
    };
    const edit_model_config_options = {};
    const editModal = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: edit_model_config,
      options: edit_model_config_options,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      editModal.visible = true;
      editModal.title = '修改部门';
      editModal.form.model = { ...record };
      currentRecord = record;
    };

    const handleDelete = (record: any) => {
      removeGroup({ group_id: record.id, org_id: current_org.id }).then(res => {
        message.success('删除部门成功');
        Object.assign(record, res);
      });
    };

    const handleRecover = (record: any) => {
      recoverGroup({ group_id: record.id, org_id: current_org.id }).then(res => {
        message.success('恢复部门成功');
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      if (!editModal.form.model.id) {
        addGroup({ ...data, org_id: current_org.id, aim_org_id: editModal.aim_org_id }).then(() => {
          message.success('新增部门成功');
          reload();
        });
      } else {
        updateGroup({
          ...data,
          org_id: current_org.id,
          aim_org_id: data.org_id,
          group_id: editModal.form.model.id,
        }).then((res: any) => {
          // currentRecord.name = res.name;
          Object.assign(currentRecord, res);
          message.success('修改部门成功');
        });
      }
      editModal.visible = false;
      // reload();
    };
    const setRowClassName = (record: any, index: number) => {
      let rowColor = index % 2 === 0 ? 'ant-pro-table-row-striped' : ''; //判断单双行，赋予不同样式
      return record === state.currentRow ? 'clickRowStyl1' : rowColor; //赋予点击行样式
    };
    watch(
      () => state.dataSource,
      () => {
        if (state.dataSource.length > 0) {
          let record: any = state.dataSource[0];

          console.log(record);
          setUserPageInfo({
            current: 1,
            ...user_search_modelRef,
            group_id: record.id,
          });
          userReload();
        }
      },
    );

    return {
      statusMap,
      tableSize,
      state,
      status_list,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      handlerOk,
      currentRecord,
      search_modelRef,
      searchValidateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      addGroup,
      updateGroup,
      removeGroup,
      recoverGroup,
      // edit
      edit_model_config,
      edit_model_config_options,
      editModal,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      search_config,
      search_config_options,
      setRowClassName,
      //user_search
      // userElRef,
      // userScreenState,
      // setUserFull,
      // exitUserFull,
      user_search_modelRef,
      userColumnState,
      userDynamicColumns,
      userDynamicColumnItems,
      userHandleColumnAllClick,
      userHandleColumnChange,
      userReset,
      userMove,

      handleUserTableChange,
      userSearch,
      setUserPageInfo,
      userStripe,
      userState,
      userReload,
      user_search_config,
      user_search_config_options,
      userSearchValidateInfos,
    };
  },
  components: {
    SearchRender,
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    [Badge.name]: Badge,
    FormModal,
  },
});
</script>
<style>
.clickRowStyl1 {
  background-color: #bbf !important;
}
</style>
